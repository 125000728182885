import { createSlice } from '@reduxjs/toolkit'
 
const initialState_ = { 
    name: '', 
    xp: 0,
    rights: {
        admin: false,
        resources: false,
        users: false,
        rooms: false
    },
    country: '',
    gender: '',
    bio: '',
    success: [],
    message: '',
    accountId: '',
    avatar: {}
};

const { actions, reducer } = createSlice({
    name: 'user',
    initialState: initialState_,
    reducers: {
        setName: (state, action) => {
            state.name = action.payload
        },
        setXp: (state, action) => {
            state.xp = action.payload
        },
        setCreatedAt: (state, action) => {
            state.createdAt = action.payload
        },
        setRights: (state, action) => {
            state.rights = action.payload
        },
        setCountry: (state, action) => {
            state.country = action.payload
        },
        setGender: (state, action) => {
            state.gender = action.payload
        },
        setBio: (state, action) => {
            state.bio = action.payload
        },
        setSuccess: (state, action) => {
            state.success = action.payload
        },
        setMessage: (state, action) => {
            state.message = action.payload
        },
        setAccountId: (state, action) => {
            state.accountId = action.payload
        },
        setAvatar: (state, action) => {
            state.avatar = action.payload
        },
        reset: (state, action) => {
            return initialState_;
        },
        resetActivity: (state, action) => {
            state.message = '';
        }
    },
});

export const { 
    setName,
    setXp,
    setCreatedAt,
    setRights,
    setCountry,
    setGender,
    setBio,
    setSuccess,
    setMessage,
    setAccountId,
    setAvatar,
    setModalConnection,
    reset,
    resetActivity
} = actions;

export default reducer;